import { Product, ProductResume } from "../../product/product.types";
import { Item } from "../types/common.types";

export interface ProductCardInputs {
    product: Product;
    isOutOfStock: boolean;
    isOutOfStockPerQuantity: boolean;
    hasNotify: boolean;
    isGiftCard: boolean;
    productAttributes: any;
    selectedAttribute: any;
    selectedPackage: any;
    selectedAttributeCardConfig: any;
    wasValueHigher10: boolean;
    newQuantity: number;
    totalStockAvailable: number;
    productInCart: any;
    availableQuantities: any;
    customizeBox: any;
    stockSignal: any;
    isDisabledActionButtons: boolean;
    isDisabledSubscribeButton: boolean;
    existsInOrder: any;
    isPreOrderedVariant: boolean;
}

export interface ProductCardSettings {
    hasStock: boolean;
    showFlavors: boolean;
    showSizes: boolean;
    showContentInfo: boolean;
    showAttributes: boolean;
    isSummary: boolean;
    isCardInOrderPage?: boolean;
    isPlaceHolder: boolean;
}

export interface ProductCardResume {
    orderId?: number;
    product: ProductResume;
    settings: Partial<ProductCardSettings>;
    productDetails?: Product;
}

export interface ProductCard {
    orderId?: number;
    product: Product;
    settings: Partial<ProductCardSettings>;
}

export interface Category extends Item {
    minimumSpend: number;
}

export enum CardTypes {
    main = 'main',
    checkAddress = 'checkAddress',
    notify = 'notify',
    notified = 'notified',
    subscribe = 'subscribe',
    summary = 'summary',
    resume = 'resume',
    none = 'none'
}