import { Component, computed, inject, input, InputSignal, output, signal, Signal, WritableSignal } from '@angular/core';
import { handleImageError } from '../../common/utils';
import { SignalsStoreService } from '../../signals-store.service';
import { ProductCard } from '../product-card-v2.types';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-product-card-v2-preferences',
  imports: [CommonModule, NgbModule],
  templateUrl: './product-card-v2-preferences.component.html',
  styleUrl: './product-card-v2-preferences.component.scss'
})
export class ProductCardV2PreferencesComponent {

  //#region Injections:
  #signalsStoreService = inject(SignalsStoreService);
  //#endregion

  //#region Inputs:
  card: InputSignal<ProductCard> = input.required();
  selectedAttributeCardConfig: InputSignal<any> = input.required();
  isDislike: InputSignal<boolean> = input.required();
  isPreference: InputSignal<boolean> = input.required();
  isFavorite: InputSignal<boolean> = input.required();
  //#endregion

  //#region Outputs:
  outPrevDislikeProduct = output();
  outHandleFavoriteChange = output();
  //#endregion

  //#region Signals
  updateLikeDislike: WritableSignal<boolean> = signal(false);
  //#endregion

  //#region Computed:
  hasSession: Signal<boolean> = computed(() => this.#signalsStoreService.hasSession());
  productName = computed(() => {
    return this.card().product.name;
  });
  //#endregion

  //#region Methods:

  handleImageError = handleImageError;

  //#endregion

}
