<section class="mobile-card">

    <div class="d-flex gap-3">
        <div class="mobile-card__img">

            <!-- Image -->
            <figure>

                <a>
                    <img />
                </a>

            </figure>

            <!-- Actions -->
            <section class="mt-2">

                <!-- Actions -->
                <div class="actions">
                    @if (hasSession()) {
                    @if (product().isSubscription) {
                    <!-- Is subscription -->

                    <button type="button" class="btn btn-outline-secondary w-100" disabled>
                        <span class="material-symbols-outlined">
                            cached
                        </span>
                    </button>

                    <!-- A la carte -->
                    @if (product().isALaCarte) {
                    <button type="button" disabled class="btn btn-secondary w-100">
                        <span class="material-symbols-outlined">
                            add
                        </span>
                    </button>
                    }

                    } @else {
                    <!-- A la carte -->
                    @if(product().isALaCarte) {
                    <button type="button" disabled class="btn btn-secondary w-100">
                        <span class="material-symbols-outlined">
                            add
                        </span>
                    </button>
                    }
                    }
                    } @else {
                    <!-- Whit out login -->
                    <button type="button" class="btn btn-secondary w-100">
                        <span class="material-symbols-outlined">
                            add
                        </span>
                    </button>
                    }
                </div>

            </section>


        </div>

        <!-- Content -->
        <div class="mobile-card__content flex-grow-1">

            <!-- Favorite, Dislike and What's inside -->
            <section class="d-flex flex-row-reverse justify-content-between gap-3">
                <div class="d-flex">
                    <!-- Like -->
                    <div class="toolbar-items">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                                fill="#38424b">
                                <path
                                    d="m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Zm0-108q96-86 158-147.5t98-107q36-45.5 50-81t14-70.5q0-60-40-100t-100-40q-47 0-87 26.5T518-680h-76q-15-41-55-67.5T300-774q-60 0-100 40t-40 100q0 35 14 70.5t50 81q36 45.5 98 107T480-228Zm0-273Z" />
                            </svg>
                        </span>
                    </div>
                    <!-- Dislike -->
                    <div class="toolbar-items">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                                fill="#38424b">
                                <path
                                    d="M240-840h440v520L400-40l-50-50q-7-7-11.5-19t-4.5-23v-14l44-174H120q-32 0-56-24t-24-56v-80q0-7 2-15t4-15l120-282q9-20 30-34t44-14Zm360 80H240L120-480v80h360l-54 220 174-174v-406Zm0 406v-406 406Zm80 34v-80h120v-360H680v-80h200v520H680Z" />
                            </svg>
                        </span>
                    </div>
                </div>
            </section>

            <!-- Content -->
            <header>
                <h1>
                    {{product().name}}
                </h1>
                @if (product().producer?.name) {
                <h2>
                    {{product().producer?.name}}
                </h2>
                }
            </header>


            <!-- Variant -->
            @if (product().attributes?.length) {
            @for (attr of product().attributes; track $index) {
            @if (attr.values.length) {
            <mat-form-field class=" rounded-selector d-flex m-0" subscriptSizing="dynamic">
                @if (attr.values.length === 1) {
                <input matInput type="text" readonly="true" [value]="attr.values[0].name">
                } @else {
                <mat-select [value]="attr.values[0].id || ''">
                    @for (item of attr.values; track $index) {
                    <mat-option [value]="item.id">{{item.name}}</mat-option>
                    }
                </mat-select>
                }
            </mat-form-field>
            }
            }
            }
            @if (product().packages?.length) {
            <mat-form-field class="rounded-selector d-flex m-0" subscriptSizing="dynamic">
                @if (product().packages?.length === 1) {
                <input matInput type="text" readonly="true" [value]="product().packages?.[0].name || ''">
                } @else {
                <mat-select [value]="product().packages?.[0].id || ''">
                    @for (item of product().packages; track $index) {
                    <mat-option [value]="item.id">{{item.name}}</mat-option>
                    }
                </mat-select>
                }
            </mat-form-field>
            }

            <!-- Price -->
            <div class="price">
                <div class="w-70">

                    <div class="d-flex gap-1">

                        <h1>

                            <span class="material-symbols-outlined rotate-icon">
                                progress_activity
                            </span>

                        </h1>

                    </div>

                    @if (product().display) {
                    <p class="h8-bold m-0">
                        {{product().display || ''}}
                    </p>
                    }

                </div>
                <div class="w-30">
                    <mat-form-field class="rounded-selector d-flex m-0" subscriptSizing="dynamic">
                        <mat-select value="1">
                            <mat-option value="1">1</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <!-- Tags -->
            @if (product().tags && product().tags?.length) {
            <div class="tags">
                @for (tag of product().tags || []; track $index) {
                <span>{{tag.name}}</span>
                }
            </div>
            }


        </div>

    </div>

</section>