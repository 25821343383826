import { Component, computed, inject, input, InputSignal, OnInit, output } from '@angular/core';
import { Product } from '../../../product/product.types';
import { CommonModule } from '@angular/common';
import { CardTypes } from '../../product-card/product-card.types';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { NumberRestrictionDirective } from '../../directives/number-restriction.directive';
import { SignalsStoreService } from '../../signals-store.service';
import { ResolutionService } from '../../resolution.service';
import { ProductCardV2Service } from '../product-card-v2.service';
import { OutOfStockComponent } from '../../product-card/out-of-stock/out-of-stock.component';

@Component({
  selector: 'app-product-card-v2-summary',
  imports: [
    CommonModule,
    OutOfStockComponent,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    NumberRestrictionDirective
  ],
  templateUrl: './product-card-v2-summary.component.html',
  styleUrl: './product-card-v2-summary.component.scss'
})
export class ProductCardV2SummaryComponent implements OnInit {

  //#region Injections
  #signalsStoreService = inject(SignalsStoreService);
  #resolutionService = inject(ResolutionService);
  #productCardV2Service = inject(ProductCardV2Service);
  //#endregion

  //#region Inputs
  product: InputSignal<Product | undefined> = input.required();
  isOutOfStock: InputSignal<boolean> = input.required();
  isOutOfStockPerQuantity: InputSignal<boolean> = input.required();
  hasNotify: InputSignal<boolean> = input.required();
  isGiftCard: InputSignal<boolean> = input.required();
  productAttributes: InputSignal<Array<any>> = input.required();
  selectedAttribute: InputSignal<any> = input.required();
  selectedPackage: InputSignal<any> = input.required();
  selectedAttributeCardConfig: InputSignal<any> = input.required();
  wasValueHigher10: InputSignal<boolean> = input.required();
  newQuantity: InputSignal<number> = input.required();
  totalStockAvailable: InputSignal<number> = input.required();
  availableQuantities: InputSignal<Array<{ val: number, name: string | number }>> = input.required();
  stockSignal: InputSignal<Map<number | string, any>> = input.required();
  isDisabledActionButtons: InputSignal<boolean> = input.required();
  isDisabledSubscribeButton: InputSignal<boolean> = input.required();
  existsInOrder: InputSignal<{ common: boolean, subscription: boolean, totalQuantity: number }> = input.required();
  isPreOrderedVariant: InputSignal<boolean> = input.required();
  skippedSubscriptionLineId: InputSignal<number | null> = input.required();
  //#endregion

  //#region Output
  outFlipCardType = output<CardTypes>();
  outModalCheckAddress = output();
  outOnAttributeChange = output<{ id: number, event: any }>();
  outOnPackageChanged = output<any>();
  outOnInputQuantityChange = output<any>();
  outOnKeyUp = output<any>();
  outOnQuantityChange = output<any>();
  outAddProductToCart = output<{ isSubscription: boolean, event: any }>();
  outOpenModalCheckAddress = output();
  outPurchaseGiftCardClicked = output();
  //#endregion

  //#region Computed
  summaryTotalPrice = computed(() => {
    const variant = this.selectedAttributeCardConfig();
    const quantity = this.newQuantity();
    const price = +(variant?.price || this.product()?.price || 0);
    const totalAmount = price * quantity;
    return totalAmount;
  });

  hasSession = computed(() => this.#signalsStoreService.hasSession());
  marketStatus = computed(() => this.#signalsStoreService.marketStatus());
  isMobile = computed(() => this.#resolutionService.isMobile());

  getButtonTextBundleSubscription = computed(() =>
    this.#getButtonText({ isBundle: true, isSubscription: true })
  );

  getButtonTextBundle = computed(() =>
    this.#getButtonText({ isBundle: true, isSubscription: false })
  );

  getButtonTextSingle = computed(() =>
    this.#getButtonText({ isBundle: false, isSubscription: false })
  );

  getButtonTextSubscription = computed(() =>
    this.#getButtonText({ isBundle: false, isSubscription: true })
  );

  //#endregion

  //#region Properties:
  cardTypes = CardTypes;
  //#endregion

  ngOnInit(): void {
  }

  //#region Methods:
  #getButtonText(conf: { isBundle: boolean, isSubscription: boolean }) {
    const product = this.product();
    if (!product) return '';
    return this.#productCardV2Service.getButtonText({
      conf,
      existsInOrder: this.existsInOrder(),
      isPreorderedVariant: this.isPreOrderedVariant(),
      product,
      isSubscribeAndSave: this.selectedAttributeCardConfig().isSubscribeSave,
      skippedSubscriptionLineId: this.skippedSubscriptionLineId()
    })
  }
  //#endregion
}
