@let suggested = (card().product && card().product.suggestedShoppingOption) || false;
<div [class.product-card]="!shouldShowMobileCard()"
    (click)="subscribeAndcustomizeBox() && cardType() !== cardTypes.subscribe && !shouldShowMobileCard() ? flipCardType(cardTypes.subscribe) : null"
    [class.suggested]="suggested && !shouldShowMobileCard()"
    [class.product-card--subscription]="cardType() == cardTypes.subscribe"
    [class.product-card--summary]="cardType() == cardTypes.summary"
    [class.product-card--notify]="cardType() == cardTypes.notify || cardType() == cardTypes.notified"
    [class.product-card--check-address]="cardType() == cardTypes.checkAddress">

    <div [class.product-card__body]="!shouldShowMobileCard()">

        @switch (cardType()) {

        @case (cardTypes.resume) {

        @let productResume = cardResume().product;
        @if (productResume) {

        @if(shouldShowMobileCard()) {
        <app-product-card-v2-resume-mobile [product]="productResume"
            [isFromWelcome]="isFromWelcome()"></app-product-card-v2-resume-mobile>
        } @else {
        <app-product-card-v2-resume [product]="productResume"
            [isFromWelcome]="isFromWelcome()"></app-product-card-v2-resume>
        }

        }

        }

        @case (cardTypes.summary) {

        <app-product-card-v2-summary [product]="product()" [isOutOfStock]="isOutOfStock()"
            [isOutOfStockPerQuantity]="isOutOfStockPerQuantity()" [hasNotify]="hasNotify" [isGiftCard]="isGiftCard()"
            [productAttributes]="productAttributes" [selectedAttribute]="selectedAttribute()"
            [selectedPackage]="selectedPackage()" [selectedAttributeCardConfig]="selectedAttributeCardConfig()"
            [wasValueHigher10]="wasValueHigher10" [newQuantity]="newQuantity()"
            [totalStockAvailable]="totalStockAvailable()" [availableQuantities]="availableQuantities()"
            [stockSignal]="stock()" [isDisabledActionButtons]="isDisabledActionButtons()"
            [isDisabledSubscribeButton]="isDisabledSubscribeButton()" [existsInOrder]="existsInOrder()"
            [isPreOrderedVariant]="isPreOrderedVariant()" [skippedSubscriptionLineId]="skippedSubscriptionLineId()"
            (outFlipCardType)="flipCardType($event)" (outModalCheckAddress)="openModalCheckAddress()"
            (outOnAttributeChange)="onAttributeChange($event.id, $event.event)"
            (outOnPackageChanged)="onPackageChanged($event)" (outOnInputQuantityChange)="onInputQuantityChange($event)"
            (outOnKeyUp)="onKeyUp($event)" (outOnQuantityChange)="onQuantityChange($event)"
            (outAddProductToCart)="addProductToCart($event.isSubscription, $event.event)"
            (outOpenModalCheckAddress)="openModalCheckAddress()"
            (outPurchaseGiftCardClicked)="purchaseGiftCardClicked()">

        </app-product-card-v2-summary>


        } @case (cardTypes.main) {

        @if(shouldShowMobileCard()) {
        <app-product-card-v2-mobile [card]="card()" [isOutOfStock]="isOutOfStock()"
            [isOutOfStockPerQuantity]="isOutOfStockPerQuantity()" [hasNotify]="hasNotify"
            [productAttributes]="productAttributes" [selectedAttribute]="selectedAttribute()"
            [selectedPackage]="selectedPackage()" [selectedAttributeCardConfig]="selectedAttributeCardConfig()"
            [wasValueHigher10]="wasValueHigher10" [newQuantity]="newQuantity()"
            [totalStockAvailable]="totalStockAvailable()" [availableQuantities]="availableQuantities()"
            [stockSignal]="stock()" [isDisabledActionButtons]="isDisabledActionButtons()"
            [isDisabledSubscribeButton]="isDisabledSubscribeButton()" [existsInOrder]="existsInOrder()"
            [isPreOrderedVariant]="isPreOrderedVariant()" [productId]="productId()" [isFromWelcome]="isFromWelcome()"
            [isDislike]="isDislike()" [isSignupFlow]="isSignupFlow()"
            [skippedSubscriptionLineId]="skippedSubscriptionLineId()"
            [subscribeAndcustomizeBox]="subscribeAndcustomizeBox()" [isFavorite]="isFavorite()"
            [isPreference]="isPreference()" (outFlipCardType)="flipCardType($event.type)"
            (outModalCheckAddress)="openModalCheckAddress()"
            (outOnAttributeChange)="onAttributeChange($event.id, $event.event)"
            (outOnPackageChanged)="onPackageChanged($event)" (outOnInputQuantityChange)="onInputQuantityChange($event)"
            (outOnKeyUp)="onKeyUp($event)" (outOnQuantityChange)="onQuantityChange($event)"
            (outAddProductToCart)="addProductToCart($event.isSubscription, $event.event)"
            (outOpenModalCheckAddress)="openModalCheckAddress()"
            (outOpenModalWhatsInside)="openModalWhatsInside($event)" (outPrevDislikeProduct)="prevDislikeProduct()"
            (outHandleFavoriteChange)="handleFavoriteChange()"
            (outSubscribeProduct)="subscribeProductMobile()"></app-product-card-v2-mobile>
        } @else {
        <app-product-card-v2-main [card]="card()" [isOutOfStock]="isOutOfStock()"
            [isOutOfStockPerQuantity]="isOutOfStockPerQuantity()" [hasNotify]="hasNotify"
            [productAttributes]="productAttributes" [selectedAttribute]="selectedAttribute()"
            [selectedPackage]="selectedPackage()" [selectedAttributeCardConfig]="selectedAttributeCardConfig()"
            [wasValueHigher10]="wasValueHigher10" [newQuantity]="newQuantity()"
            [totalStockAvailable]="totalStockAvailable()" [availableQuantities]="availableQuantities()"
            [stockSignal]="stock()" [isDisabledActionButtons]="isDisabledActionButtons()"
            [isDisabledSubscribeButton]="isDisabledSubscribeButton()" [existsInOrder]="existsInOrder()"
            [isPreOrderedVariant]="isPreOrderedVariant()" [productId]="productId()" [isFromWelcome]="isFromWelcome()"
            [isDislike]="isDislike()" [isSignupFlow]="isSignupFlow()"
            [skippedSubscriptionLineId]="skippedSubscriptionLineId()"
            [subscribeAndcustomizeBox]="subscribeAndcustomizeBox()" [isFavorite]="isFavorite()"
            (outFlipCardType)="flipCardType($event.type)" (outModalCheckAddress)="openModalCheckAddress()"
            (outOnAttributeChange)="onAttributeChange($event.id, $event.event)"
            (outOnPackageChanged)="onPackageChanged($event)" (outOnInputQuantityChange)="onInputQuantityChange($event)"
            (outOnKeyUp)="onKeyUp($event)" (outOnQuantityChange)="onQuantityChange($event)"
            (outAddProductToCart)="addProductToCart($event.isSubscription, $event.event)"
            (outOpenModalCheckAddress)="openModalCheckAddress()"
            (outOpenModalWhatsInside)="openModalWhatsInside($event)" (outPrevDislikeProduct)="prevDislikeProduct()"
            (outHandleFavoriteChange)="handleFavoriteChange()">
        </app-product-card-v2-main>
        }

        } @case (cardTypes.notify) {

        <app-product-card-v2-notify [productId]="productId()" (outFlipCardType)="flipCardType($event)"
            [isSummary]="!!card().settings.isSummary">
        </app-product-card-v2-notify>

        } @case (cardTypes.notified) {

        <app-product-card-v2-notified (outFlipCardType)="flipCardType($event)"
            [isSummary]="!!card().settings.isSummary"></app-product-card-v2-notified>

        } @case (cardTypes.subscribe) {

        <app-product-card-v2-subscribe [card]="this.card()" [deliveryDay]="deliveryDay()" [minDate]="minDate()"
            (outFlipCardType)="flipCardType($event.type, $event.isSignupFlow, $event.event)"
            (onSelectedFrequencyChange)="selectedFrequency.set($event)"
            (onStartDateChange)="selectedDateSubscription.set($event)"
            (outAddProductToCart)="addProductToCart($event.isSubscription, $event.event)"
            (onInitiateSubscriptionImmediatlyChanged)="initiateSubscriptionImmediatly.set($event)"
            [subscribeAndcustomizeBox]="subscribeAndcustomizeBox()" (outContinue)="continue()">
        </app-product-card-v2-subscribe>

        } @case (cardTypes.preferences) {

        @if(shouldShowMobileCard()) {
        <app-product-card-v2-mobile [card]="card()" [isOutOfStock]="isOutOfStock()"
            [isOutOfStockPerQuantity]="isOutOfStockPerQuantity()" [hasNotify]="hasNotify"
            [productAttributes]="productAttributes" [selectedAttribute]="selectedAttribute()"
            [selectedPackage]="selectedPackage()" [selectedAttributeCardConfig]="selectedAttributeCardConfig()"
            [wasValueHigher10]="wasValueHigher10" [newQuantity]="newQuantity()"
            [totalStockAvailable]="totalStockAvailable()" [availableQuantities]="availableQuantities()"
            [stockSignal]="stock()" [isDisabledActionButtons]="isDisabledActionButtons()"
            [isDisabledSubscribeButton]="isDisabledSubscribeButton()" [existsInOrder]="existsInOrder()"
            [isPreOrderedVariant]="isPreOrderedVariant()" [productId]="productId()" [isFromWelcome]="isFromWelcome()"
            [isDislike]="isDislike()" [isSignupFlow]="isSignupFlow()"
            [skippedSubscriptionLineId]="skippedSubscriptionLineId()"
            [subscribeAndcustomizeBox]="subscribeAndcustomizeBox()" [isFavorite]="isFavorite()"
            [isPreference]="isPreference()" (outFlipCardType)="flipCardType($event.type)"
            (outModalCheckAddress)="openModalCheckAddress()"
            (outOnAttributeChange)="onAttributeChange($event.id, $event.event)"
            (outOnPackageChanged)="onPackageChanged($event)" (outOnInputQuantityChange)="onInputQuantityChange($event)"
            (outOnKeyUp)="onKeyUp($event)" (outOnQuantityChange)="onQuantityChange($event)"
            (outAddProductToCart)="addProductToCart($event.isSubscription, $event.event)"
            (outOpenModalCheckAddress)="openModalCheckAddress()"
            (outOpenModalWhatsInside)="openModalWhatsInside($event)" (outPrevDislikeProduct)="prevDislikeProduct()"
            (outHandleFavoriteChange)="handleFavoriteChange()"
            (outSubscribeProduct)="subscribeProductMobile()"></app-product-card-v2-mobile>
        } @else {

        <app-product-card-v2-preferences [card]="card()" [selectedAttributeCardConfig]="selectedAttributeCardConfig()"
            [isDislike]="isDislike()" [isPreference]="isPreference()" [isFavorite]="isFavorite()"
            (outPrevDislikeProduct)="prevDislikeProduct()" (outHandleFavoriteChange)="handleFavoriteChange()">
        </app-product-card-v2-preferences>
        }
        
        }

        }

    </div>

</div>