<section class="animated fadeIn d-flex flex-column flex-grow-1">
    <div class="row">
        <i class="fas fa-times text-end close-mark"
            (click)="outFlipCardType.emit(isSummary() ? cardTypes.summary : cardTypes.main)"></i>
    </div>
    <div class="d-flex h-100 justify-content-center align-items-center px-1 flex-column text-center">
        <h4 class="">
            You'll be notified!
        </h4>
        <p>Thank you for your interest in this product. We will let you know when it's back in stock.</p>
    </div>
</section>