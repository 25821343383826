<section class="d-flex flex-column flex-grow-1" (mouseenter)="showText.set(true)" (mouseleave)="showText.set(false)">

    <div class="product-card__img-wrap" [ngClass]="{'card-img active-filter-blur': isOutOfStock()}">

        <!-- Fav Icon -->
        @if (hasSession()) {
        @if(!isSignupFlow()) {
        <div class="product-card__pre-head">

            <!-- Like -->

            @if (isFavorite()) {
            <span (click)="outHandleFavoriteChange.emit()">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                    fill="#38424b">
                    <path
                        d="m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Z" />
                </svg>
            </span>
            } @else {
            <span (click)="outHandleFavoriteChange.emit()">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                    fill="#38424b">
                    <path
                        d="m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Zm0-108q96-86 158-147.5t98-107q36-45.5 50-81t14-70.5q0-60-40-100t-100-40q-47 0-87 26.5T518-680h-76q-15-41-55-67.5T300-774q-60 0-100 40t-40 100q0 35 14 70.5t50 81q36 45.5 98 107T480-228Zm0-273Z" />
                </svg>
            </span>
            }

            <!-- Dislike -->

            @if (isDislike()) {
            <span (click)="outPrevDislikeProduct.emit()">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                    fill="#38424b">
                    <path
                        d="M240-840h400v520L360-40l-50-50q-7-7-11.5-19t-4.5-23v-14l44-174H120q-32 0-56-24t-24-56v-80q0-7 1.5-15t4.5-15l120-282q9-20 30-34t44-14Zm480 520v-520h160v520H720Z" />
                </svg>
            </span>
            } @else {
            <span (click)="outPrevDislikeProduct.emit()">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                    fill="#38424b">
                    <path
                        d="M240-840h440v520L400-40l-50-50q-7-7-11.5-19t-4.5-23v-14l44-174H120q-32 0-56-24t-24-56v-80q0-7 2-15t4-15l120-282q9-20 30-34t44-14Zm360 80H240L120-480v80h360l-54 220 174-174v-406Zm0 406v-406 406Zm80 34v-80h120v-360H680v-80h200v520H680Z" />
                </svg>
            </span>
            }

        </div>
        }
        }

        <!-- Sale Badge -->
        @if(showSaleBadge()) {
        <div class="sale-badge"><span class="material-symbols-outlined">percent</span></div>
        }

        <!-- Image -->
        @if(!isSignupFlow()) {
        <a [routerLink]="productRouterLink()">
            @defer (on viewport; prefetch on immediate) {
            <img [src]="productImg()" alt="Image of {{productName()}}" (error)="handleImageError($event)"
                class="product-card__img" [ngClass]="{'disliked': isDislike()}">
            } @placeholder {
            <div style="height: 150px;"></div>
            }
        </a>
        } @else {
        <a>
            @defer (on viewport; prefetch on immediate) {
            <img [src]="productImg()" (error)="handleImageError($event)" class="product-card__img"
                alt="Image of {{productName()}}">
            } @placeholder {
            <div style="height: 150px;"></div>
            }
        </a>
        }

        <!-- Action button -->
        @if (!isOutOfStock() && !isOutOfStockPerQuantity() && !isSignupFlow()) {
        <div class="product-card__action">
            <div class="d-grid gap-2">
                @if (hasSession()) {
                @if (selectedAttributeCardConfig().isSubscription) {
                @if (card().product.bundle?.items?.length) {
                <div [class.action-col-2]="marketStatus().isOpen && selectedAttributeCardConfig().isALaCarte">
                    @if(stockSignal().size) {
                    <button type="button" [disabled]="isDisabledSubscribeButton()"
                        (click)="outFlipCardType.emit({type: cardTypes.subscribe})"
                        class="btn btn-outline-secondary d-flex gap-2 align-items-center"
                        [ngbTooltip]="!!skippedSubscriptionLineId() ? 'Unskip' : ''" placement="left">
                        <span class="material-symbols-outlined">
                            {{!!skippedSubscriptionLineId() ? 'restart_alt' : 'cached'}}
                        </span>
                    </button>
                    @if (marketStatus().isOpen && selectedAttributeCardConfig().isALaCarte) {
                    <button type="button" (click)="outAddProductToCart.emit({isSubscription: false, event: $event})"
                        [disabled]="isDisabledActionButtons()"
                        class="btn btn-secondary d-flex gap-2 align-items-center">
                        <span class="material-symbols-outlined">
                            add
                        </span>
                    </button>
                    }
                    } @else {
                    <p class="placeholder-glow mb-0 w-100">
                        <span
                            class="placeholder w-100 col-12 bg-secondary rounded btn btn-secondary d-flex gap-2 align-items-center"
                            style="height: 36px;"></span>
                    </p>
                    }
                </div>
                }
                @else {
                <div [class.action-col-2]="marketStatus().isOpen && selectedAttributeCardConfig().isALaCarte">
                    @if(stockSignal().size) {
                    <button type="button" [disabled]="isDisabledSubscribeButton()"
                        (click)="outFlipCardType.emit({type: cardTypes.subscribe})"
                        class="btn btn-outline-secondary d-flex gap-2 align-items-center"
                        [ngbTooltip]="!!skippedSubscriptionLineId() ? 'Unskip' : ''" placement="left">
                        <span class="material-symbols-outlined">
                            {{!!skippedSubscriptionLineId() ? 'restart_alt' : 'cached'}}
                        </span>
                    </button>
                    @if (marketStatus().isOpen && selectedAttributeCardConfig().isALaCarte) {
                    <button type="button" (click)="outAddProductToCart.emit({isSubscription: false, event: $event})"
                        [disabled]="isDisabledActionButtons()"
                        class="btn btn-secondary d-flex gap-2 align-items-center">
                        <span class="material-symbols-outlined">
                            add
                        </span>
                    </button>
                    }
                    } @else {
                    <p class="placeholder-glow mb-0 w-100">
                        <span
                            class="placeholder w-100 col-12 bg-secondary rounded btn btn-secondary d-flex gap-2 align-items-center"
                            style="height: 36px;"></span>
                    </p>
                    }
                </div>
                }
                }
                @else {
                @if(stockSignal().size && selectedAttributeCardConfig().isALaCarte) {
                <button type="button" (click)="outAddProductToCart.emit({isSubscription: false, event: $event})"
                    [disabled]="isDisabledActionButtons() || (!card().product.preOrder && !marketStatus().isOpen)"
                    class="btn btn-secondary d-flex gap-2 align-items-center">
                    <span class="material-symbols-outlined">
                        add
                    </span>
                </button>
                } @else {
                <p class="placeholder-glow mb-0 w-100">
                    <span
                        class="placeholder w-100 col-12 bg-secondary rounded btn btn-secondary d-flex gap-2 align-items-center"
                        style="height: 36px;"></span>
                </p>
                }
                }
                }
                @else {
                <button type="button" (click)="outOpenModalCheckAddress.emit()"
                    class="btn btn-secondary d-flex gap-2 align-items-center">
                    <!-- Sign Up -->
                    <span class="material-symbols-outlined">
                        add
                    </span>
                </button>
                }
            </div>
        </div>
        }

        <!-- Subscribed or added -->
        <div class="card-action-labels">

            @if (!!skippedSubscriptionLineId()) {
            <span class="bg-warning text-white">
                Skipped
            </span>
            } @else {
            @if (existsInOrder().subscription){
            <span>Subscribed</span>
            }

            @if (existsInOrder().common){
            <span>Added</span>
            }
            }

        </div>

        <!-- Hovered Button over main image -->
        @if (card().product.bundle?.items?.length) {
        <div class="info-badge">
            <button type="button" class="rolling-info" [class.active]="showText()"
                (click)="outOpenModalWhatsInside.emit($event)">

                @if (!showText()) {
                <i class="rolling-info-icon fas fa-info-circle"></i>
                }

                <span class="rolling-info__text" [class.active]="showText()">
                    What's inside
                </span>
            </button>
        </div>
        }

        @if (!isSignupFlow()) {
        @if (!marketStatus().isOpen && !card().product.preOrder && !selectedAttributeCardConfig().isSubscription) {
        <div class="d-grid position-absolute bottom-0 w-100">
            <div class="mb-0 w-100 alert py-2 px-3 alert-warning d-flex align-items-center animated fadeIn rounded-0"
                role="alert">
                @if (isMobile()) {
                <div class="h8-regular text-center">
                    Available <b>on {{marketStatus().openingDay | titlecase}}</b>
                </div>
                } @else {
                <div class="h7-regular">
                    You'll be <b>able to add</b> items to your cart on <b>{{marketStatus().openingDay | titlecase}}</b>
                </div>
                }
            </div>
        </div>
        }
        }
    </div>

    <!-- Card Header - Title and Subtitle -->
    <div class="product-card__head" [ngClass]="{'card-img active-filter-blur h-auto': isOutOfStock()}">
        @if(!isSignupFlow()) {
        <a [routerLink]="productRouterLink()">
            <h3 class="product-card__title line-clamp-1" [ngbTooltip]="productName()">{{productName()}}
            </h3>
        </a>
        } @else {
        <a>
            <h3 class="product-card__title" [class.line-clamp-2]="subscribeAndcustomizeBox()"
                [ngbTooltip]="productName()">{{productName()}}</h3>
        </a>
        }
        <h5 class="product-card__subtitle">
            {{producerName()}}
        </h5>
    </div>

    @if (isOutOfStock() || isOutOfStockPerQuantity()) {
    <app-out-of-stock [isOutOfStockPerQuantity]="isOutOfStockPerQuantity()" [isOutOfStock]="isOutOfStock()"
        [hasNotify]="hasNotify()" [isFromSummary]="false" (outFlipCardType)="outFlipCardType.emit({type: $event})"
        (outModalCheckAddress)="outOpenModalCheckAddress.emit()"></app-out-of-stock>
    } @else {
    <section [class.opacity-0]="isOutOfStock()">
        <div class="product-card__meta">
            <div class="product-card__meta--item is-col-2">
                <div class="d-grid row-gap-1">

                    <div class="d-flex gap-1 d-flex gap-1 flex-row align-items-center">

                        @if(!selectedAttributeCardConfig().isABundle &&
                        !selectedAttributeCardConfig().bundle?.items?.length &&
                        selectedAttributeCardConfig().bundle?.priceRange) {
                        <h5 class="product-card__price">{{ selectedAttributeCardConfig().bundle?.priceRange }}</h5>
                        } @else {
                        <h5 class="product-card__price">{{ selectedAttributeCardConfig().price | currency }}</h5>
                        }

                        @if(selectedAttributeCardConfig().hasDiscount) {
                        <small class="product-card__price--line-through fw-bold text-decoration-line-through">
                            {{selectedAttributeCardConfig().originalPrice | currency}}
                        </small>
                        }

                    </div>

                    @if (selectedAttributeCardConfig().display) {
                    <p class="product-card__info">{{selectedAttributeCardConfig().display || ''}}</p>
                    }
                </div>

                <!-- Quantity buttons -->
                <div class="col quantity" [class.justify-content-center]="subscribeAndcustomizeBox()"
                    [class.mt-how-many]="wasValueHigher10()">
                    @if (wasValueHigher10()) {
                    <small class="how-many-label d-none d-sm-block">How many?</small>
                    }
                    <mat-form-field class="rounded-selector d-flex" subscriptSizing="dynamic">
                        @if( wasValueHigher10() || newQuantity() >= 10 || (newQuantity() >= 10 && wasValueHigher10()) )
                        {
                        <input type="text" matInput appNumberRestriction [minNumber]="1"
                            [maxNumber]="totalStockAvailable()" (change)="outOnInputQuantityChange.emit($event)"
                            (keyup)="outOnKeyUp.emit($event)" [value]="newQuantity()">
                        }
                        @else{
                        <mat-select [value]="newQuantity()" (selectionChange)="outOnQuantityChange.emit($event)">
                            @for(q of availableQuantities(); track $index){
                            <mat-option [value]="q.val">{{ q.name }}</mat-option>
                            }
                        </mat-select>
                        }
                    </mat-form-field>
                </div>

            </div>

            @if (selectedAttributeCardConfig().teaser && subscribeAndcustomizeBox()) {
            <p class="product-card__teaser" [innerHTML]="selectedAttributeCardConfig().teaser"></p>
            }

            <!-- Product Customizations  -->
            @if (card().settings.showAttributes) {
            <div class="row">
                <div class="col-12 d-flex gap-1">
                    @if (productAttributes.length) {
                    @for (attr of productAttributes(); track $index) {
                    @if (attr.values.length) {
                    <mat-form-field class="rounded-selector" subscriptSizing="dynamic">
                        <mat-label class="text-capitalize">{{attr.name.toLowerCase()}}</mat-label>
                        @if (card().product.variantId || attr.values.length === 1) {
                        <input matInput type="text" readonly="true" [value]="selectedAttribute()?.value.name">
                        } @else {
                        <mat-select [value]="selectedAttribute()?.value?.id || ''"
                            (selectionChange)="outOnAttributeChange.emit({id: attr.id, event: $event})">
                            @for (item of attr.values; track $index) {
                            <mat-option [value]="item.id">{{item.name}}</mat-option>
                            }
                        </mat-select>
                        }
                    </mat-form-field>
                    }
                    }
                    }
                    @if (card().product.packages?.length) {
                    <mat-form-field class="rounded-selector" subscriptSizing="dynamic">
                        <mat-label class="text-capitalize">Package</mat-label>
                        @if (card().product.packages?.length === 1) {
                        <input matInput type="text" readonly="true" [value]="selectedPackage()?.name">
                        } @else {
                        <mat-select [value]="selectedPackage()?.id || ''"
                            (selectionChange)="outOnPackageChanged.emit($event.value)">
                            @for (item of card().product.packages; track $index) {
                            <mat-option [value]="item.id">{{item.name}}</mat-option>
                            }
                        </mat-select>
                        }
                    </mat-form-field>
                    }
                </div>
            </div>

            } @else {
            <div class="height-product-custom"></div>
            }

            <!-- Tags Icons -->
            @let tags = card().product.tags || [];
            @if (tags.length) {
            <div class="my-2">
                <ul class="product-card__tags">
                    @for (tag of tags; track $index) {
                    <li>
                        {{tag.name}}
                    </li>
                    }
                </ul>
            </div>
            }

        </div>

    </section>
    }

</section>