<i class="fas fa-times close-mark"
    (click)="outFlipCardType.emit({type: (card().settings.isSummary || false ? cardTypes.summary : cardTypes.main), isSignupFlow: false, event: $event})"></i>
<section class="animated fadeIn ">

    <div>
        <h4 class="text-center h3-semibold">Product subscription</h4>
        @if (subscribeAndcustomizeBox()) {
        <p>How often would you want to receive your farmbox?</p>
        } @else {
        <p>When would you like to receive your subscription item(s)?</p>
        }
        <mat-form-field class="rounded-selector mb-3 d-flex" subscriptSizing="dynamic">
            <mat-label> Frequency </mat-label>
            <mat-select (selectionChange)="onChangeFrequency($event)" [value]="selectedFrequency?.id">
                @for(freq of frequencies(); track $index){
                <mat-option [value]="freq.id">{{ freq.name }}</mat-option>
                }
            </mat-select>
        </mat-form-field>
        @if (!subscribeAndcustomizeBox()) {
        @if (!isTemporaryRouteChange()) {
        <mat-checkbox [checked]="true" class="mb-3"
            (change)="initiateSubscriptionImmediatly.set($event.checked)">Initiate my subscription
            immediately</mat-checkbox>
        }
        @if (initiateSubscriptionImmediatly()) {
        <app-empty-message [width100]="true" [mt0]="true"
            [message]="'Next Delivery: ' + deliveryZoneInfo()?.readeableDeliveryDate"></app-empty-message>
        } @else {
        <mat-form-field class="w-100 mb-3 animated fadeIn">
            <mat-label>Start subscription date</mat-label>
            <input matInput [min]="minDate()" [matDatepicker]="startSubscription" [matDatepickerFilter]="dateFilter"
                readonly (dateChange)="onDateChange($event)" (click)="startSubscription.open()">
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="startSubscription"></mat-datepicker-toggle>
            <mat-datepicker [yPosition]="'below'" #startSubscription disabled="false"></mat-datepicker>
        </mat-form-field>
        }
        }
    </div>
    <div class="card-footer p-0 mt-1 mt-auto">
        <div class="row">
            @if (subscribeAndcustomizeBox()) {
            <div class="col">
                <button type="button" (click)="outContinue.emit()"
                    [disabled]="!selectedFrequency || (!this.initiateSubscriptionImmediatly() && !this.selectedDateSubscription)"
                    class="btn btn-secondary d-flex gap-2 align-items-center">
                    Subscribe & Continue
                </button>
            </div>
            } @else {
            <div class="col">
                <button type="button"
                    (click)="outFlipCardType.emit({type: card().settings.isSummary || false ? cardTypes.summary : cardTypes.main, isSignupFlow: false, event: $event })"
                    class="btn btn-outline-secondary d-flex gap-2 align-items-center">
                    Cancel
                </button>
            </div>
            <div class="col">
                <button type="button" (click)="outAddProductToCart.emit({isSubscription: true, event: $event})"
                    [disabled]="!selectedFrequency || (!this.initiateSubscriptionImmediatly() && !this.selectedDateSubscription)"
                    class="btn btn-secondary d-flex gap-2 align-items-center">
                    Subscribe
                </button>
            </div>
            }
        </div>
    </div>

</section>