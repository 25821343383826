<section class="animated fadeIn d-flex flex-column flex-grow-1">
    <div class="product-card__img-wrap">

        <!-- Fav Icon -->
        @if (hasSession()) {
        <div class="product-card__pre-head">

            <!-- Like -->

            @if (isFavorite()) {
            <span (click)="outHandleFavoriteChange.emit()">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                    fill="#38424b">
                    <path
                        d="m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Z" />
                </svg>
            </span>
            } @else {
            <span (click)="outHandleFavoriteChange.emit()">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                    fill="#38424b">
                    <path
                        d="m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Zm0-108q96-86 158-147.5t98-107q36-45.5 50-81t14-70.5q0-60-40-100t-100-40q-47 0-87 26.5T518-680h-76q-15-41-55-67.5T300-774q-60 0-100 40t-40 100q0 35 14 70.5t50 81q36 45.5 98 107T480-228Zm0-273Z" />
                </svg>
            </span>
            }

            <!-- Dislike -->

            @if (isDislike()) {
            <span (click)="outPrevDislikeProduct.emit()">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                    fill="#38424b">
                    <path
                        d="M240-840h400v520L360-40l-50-50q-7-7-11.5-19t-4.5-23v-14l44-174H120q-32 0-56-24t-24-56v-80q0-7 1.5-15t4.5-15l120-282q9-20 30-34t44-14Zm480 520v-520h160v520H720Z" />
                </svg>
            </span>
            } @else {
            <span (click)="outPrevDislikeProduct.emit()">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                    fill="#38424b">
                    <path
                        d="M240-840h440v520L400-40l-50-50q-7-7-11.5-19t-4.5-23v-14l44-174H120q-32 0-56-24t-24-56v-80q0-7 2-15t4-15l120-282q9-20 30-34t44-14Zm360 80H240L120-480v80h360l-54 220 174-174v-406Zm0 406v-406 406Zm80 34v-80h120v-360H680v-80h200v520H680Z" />
                </svg>
            </span>
            }

        </div>
        }

        <!-- Image -->
        @defer (on viewport; prefetch on immediate) {
        <img [src]="selectedAttributeCardConfig().image || 'assets/images/product-placeholder.webp'"
            (error)="handleImageError($event)" class="product-card__img" [ngClass]="{'disliked': isDislike()}"
            alt="Image of {{productName()}}">
        } @placeholder {
        <div style="height: 150px;"></div>
        }

        @if (isFavorite()) {
        <div class="liked">
        </div>
        }

    </div>

    <div class="product-card__head">
        <h3 class="product-card__title" [class.font-size-6]="isPreference()" [class.line-clamp-2]="isPreference()"
            [ngbTooltip]="productName()">
            {{productName()}}</h3>
        <h5 class="product-card__subtitle">{{card().product.producer?.name ?? ''}}</h5>
    </div>

</section>