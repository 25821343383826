<section class="mobile-card" [class.suggested-mobile]="card().product.suggestedShoppingOption"
    (click)="subscribeAndcustomizeBox() ? outSubscribeProduct.emit() : null">

    <div class="d-flex gap-3">
        <div class="mobile-card__img">

            <!-- Image -->
            @defer (on viewport; prefetch on immediate) {
            <figure>

                <!-- Sale Badge -->
                @if(selectedAttributeCardConfig().hasDiscount && selectedAttributeCardConfig().isOnSale) {
                <div class="sale-badge">
                    <span class="material-symbols-outlined">
                        percent
                    </span>
                </div>
                }

                @if(!isSignupFlow() && !isPreference()) {
                <a [routerLink]="productRouterLink()" [class.opacity-img]="isOutOfStock()">
                    <img [src]="selectedAttributeCardConfig().image || 'assets/images/product-placeholder.webp'"
                        [ngClass]="{'disliked': isDislike()}" (error)="handleImageError($event)"
                        alt="{{productName()}}" />
                </a>
                } @else {
                <img [src]="selectedAttributeCardConfig().image || 'assets/images/product-placeholder.webp'"
                    (error)="handleImageError($event)" [ngClass]="{'disliked': isDislike()}" alt="{{productName()}}" />
                }

                @if (isFavorite() && isPreference()) {
                <div class="liked">
                </div>
                }

                @if (!isSignupFlow()) {
                @if (!marketStatus().isOpen && !card().product.preOrder &&
                !selectedAttributeCardConfig().isSubscription) {
                <div class="d-grid position-absolute bottom-0 w-100">
                    <div class="mb-0 w-100 alert py-2 px-3 alert-warning d-flex align-items-center animated fadeIn rounded-0"
                        role="alert">
                        <div class="h8-regular text-center">
                            Available <b>on {{marketStatus().openingDay | titlecase}}</b>
                        </div>
                    </div>
                </div>
                }
                }

            </figure>
            } @placeholder {
            <figure></figure>
            }

            <!-- Actions -->
            @if (!isSignupFlow() && !isPreference()) {
            <section class="mt-2">

                <!-- Actions -->
                @if (!(!isOutOfStockPerQuantity() && hasNotify())) {
                @if (!isOutOfStock()) {
                <div class="actions">
                    @if (hasSession()) {
                    @if (selectedAttributeCardConfig().isSubscription) {
                    <!-- Is subscription -->

                    @if(stockSignal().size) {
                    <button type="button" [disabled]="isDisabledSubscribeButton()"
                        class="btn btn-outline-secondary w-100" (click)="outSubscribeProduct.emit()">
                        <span class="material-symbols-outlined">
                            {{!!skippedSubscriptionLineId() ? 'restart_alt' : 'cached'}}
                        </span>
                    </button>
                    }

                    <!-- A la carte -->
                    @if (marketStatus().isOpen && selectedAttributeCardConfig().isALaCarte) {
                    <button type="button" [disabled]="isDisabledActionButtons() || !marketStatus().isOpen"
                        class="btn btn-secondary w-100"
                        (click)="outAddProductToCart.emit({isSubscription: false, event: $event})">
                        <span class="material-symbols-outlined">
                            add
                        </span>
                    </button>
                    }

                    } @else {
                    <!-- A la carte -->
                    @if(stockSignal().size && selectedAttributeCardConfig().isALaCarte) {
                    <button type="button"
                        [disabled]="isDisabledActionButtons() || (!card().product.preOrder && !marketStatus().isOpen)"
                        class="btn btn-secondary w-100"
                        (click)="outAddProductToCart.emit({isSubscription: false, event: $event})">
                        <span class="material-symbols-outlined">
                            add
                        </span>
                    </button>
                    }
                    }
                    } @else {
                    <!-- Whit out login -->
                    <button type="button" class="btn btn-secondary w-100" (click)="outOpenModalCheckAddress.emit()">
                        <span class="material-symbols-outlined">
                            add
                        </span>
                    </button>
                    }
                </div>
                }
                } @else {
                <div class="actions">
                    @if (hasSession()) {
                    <button type="button" class="btn btn-secondary w-100">
                        Notify me
                    </button>
                    }@else {
                    <button type="button" class="btn btn-secondary w-100" (click)="outOpenModalCheckAddress.emit()">
                        Sign up
                    </button>
                    }
                </div>
                }

            </section>
            }

        </div>

        <!-- Content -->
        @if (isOutOfStock() && !isPreference()){
        <div class="mobile-card__content justify-content-center flex-grow-1">
            <!-- Like & Dislike -->
            @if(hasSession() && (!isSignupFlow() || isPreference())) {
            <section class="d-flex flex-row-reverse justify-content-between gap-3">
                <ng-container [ngTemplateOutlet]="productActions"></ng-container>
            </section>
            }

            <div class="text-center">

                @if (isSignupFlow() || isPreference()) {
                <header class="text-start">
                    <h1>
                        {{productName()}}
                    </h1>
                    @if (producerName()) {
                    <h2>
                        {{producerName()}}
                    </h2>
                    }
                </header>
                } @else {
                <header class="text-start" [routerLink]="productRouterLink()">
                    <h1>
                        {{productName()}}
                    </h1>
                    @if (producerName()) {
                    <h2>
                        {{producerName()}}
                    </h2>
                    }
                </header>
                }

                <h1 class="h7-semibold m-0 mt-4">
                    Out of stock
                </h1>
                <span class="h8-regular text-start">
                    @if (!isOutOfStockPerQuantity()) {
                    @if (hasNotify()) {
                    This product is currently out of stock, let us know if you want to be notify when it’s available
                    again
                    } @else {
                    This product is temporarily out of stock.
                    }
                    } @else {
                    There are not that many units available. Please select a different quantity.
                    }
                </span>
            </div>
        </div>
        } @else {
        <div class="mobile-card__content flex-grow-1">

            <!-- Favorite, Dislike and What's inside -->
            <section class="d-flex flex-row-reverse justify-content-between gap-3">
                @if(hasSession()) {
                <!-- Like & Dislike -->
                @if (!isSignupFlow() || isPreference()) {
                <ng-container [ngTemplateOutlet]="productActions"></ng-container>
                }
                }
                <!-- What's inside -->
                @if (card().product.bundle?.items?.length && !isPreference()) {
                <div class="toolbar-items" (click)="outOpenModalWhatsInside.emit($event)">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                        fill="#736e6e">
                        <path
                            d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
                    </svg>
                </div>
                }
            </section>

            @if (!isPreference()) {
            @if (!!skippedSubscriptionLineId()) {
            <div class="labels">
                <span class="bg-warning text-white">
                    Skipped
                </span>
            </div>
            } @else if (existsInOrder().subscription || existsInOrder().common) {
            <div class="labels">
                @if (existsInOrder().subscription) {
                <span>Subscribed</span>
                }
                @if (existsInOrder().common) {
                <span>Added</span>
                }
            </div>
            }
            }

            <!-- Content -->
            @if (isSignupFlow() || isPreference()) {
            <header>
                <h1>
                    {{productName()}}
                </h1>
                @if (producerName()) {
                <h2>
                    {{producerName()}}
                </h2>
                }
            </header>
            } @else {
            <header [routerLink]="productRouterLink()">
                <h1>
                    {{productName()}}
                </h1>
                @if (producerName()) {
                <h2>
                    {{producerName()}}
                </h2>
                }
            </header>
            }

            @if (!isPreference()) {
            <!-- Variant -->
            @if (card().settings.showAttributes) {
            @if (productAttributes.length) {
            @for (attr of productAttributes(); track $index) {
            <mat-form-field class=" rounded-selector d-flex m-0" subscriptSizing="dynamic">
                @if (card().product.variantId || attr.values.length === 1) {
                <input matInput type="text" readonly="true" [value]="selectedAttribute()?.value.name">
                } @else {
                <mat-select [value]="selectedAttribute()?.value?.id || ''"
                    (selectionChange)="outOnAttributeChange.emit({id: attr.id, event: $event})">
                    @for (item of attr.values; track $index) {
                    <mat-option [value]="item.id">{{item.name}}</mat-option>
                    }
                </mat-select>
                }
            </mat-form-field>
            }
            }
            @if (card().product.packages?.length) {
            <mat-form-field class="rounded-selector d-flex m-0" subscriptSizing="dynamic">
                @if (card().product.packages?.length === 1) {
                <input matInput type="text" readonly="true" [value]="selectedPackage()?.name">
                } @else {
                <mat-select [value]="selectedPackage()?.id || ''"
                    (selectionChange)="outOnPackageChanged.emit($event.value)">
                    @for (item of card().product.packages; track $index) {
                    <mat-option [value]="item.id">{{item.name}}</mat-option>
                    }
                </mat-select>
                }
            </mat-form-field>
            }
            }

            <!-- Price -->
            <div class="price">
                <div class="w-70">

                    <div class="d-flex gap-1">

                        @if(!selectedAttributeCardConfig().isABundle &&
                        !selectedAttributeCardConfig().bundle?.items?.length &&
                        selectedAttributeCardConfig().bundle?.priceRange) {
                        <h1>
                            {{selectedAttributeCardConfig().bundle?.priceRange}}
                        </h1>
                        } @else {
                        <h1>
                            {{selectedAttributeCardConfig().price | currency}}
                        </h1>
                        }

                        @if(selectedAttributeCardConfig().hasDiscount) {
                        <small class="text-body-tertiary fw-bold text-decoration-line-through">
                            {{selectedAttributeCardConfig().originalPrice | currency}}
                        </small>
                        }
                    </div>

                    @if (selectedAttributeCardConfig().display) {
                    <p class="h8-bold m-0">
                        {{selectedAttributeCardConfig().display || ''}}
                    </p>
                    }

                </div>
                <div class="w-30">
                    <mat-form-field class="rounded-selector d-flex m-0" subscriptSizing="dynamic">
                        @if( wasValueHigher10() || newQuantity() >= 10 || (newQuantity() >= 10 && wasValueHigher10()) ){
                        <input type="text" matInput appNumberRestriction [minNumber]="1" [maxNumber]="100"
                            (change)="outOnInputQuantityChange.emit($event)" (keyup)="outOnKeyUp.emit($event)"
                            [value]="newQuantity()">
                        } @else {
                        <mat-select [value]="newQuantity()" (selectionChange)="outOnQuantityChange.emit($event)">
                            @for(q of availableQuantities(); track $index){
                            <mat-option [value]="q.val">{{ q.name }}</mat-option>
                            }
                        </mat-select>
                        }
                    </mat-form-field>
                </div>
            </div>

            <!-- Tags -->
            @if (card().product.tags && card().product.tags.length) {
            <div class="tags">
                @for (tag of card().product.tags || []; track $index) {
                <span>{{tag.name}}</span>
                }
            </div>
            }
            }

        </div>
        }
    </div>

    @if (selectedAttributeCardConfig().teaser && subscribeAndcustomizeBox()) {
    <p class="product-card__teaser" [innerHTML]="selectedAttributeCardConfig().teaser"></p>
    }

</section>

<!-- Like & Dislike -->
<ng-template #productActions>
    <div class="d-flex">
        <!-- Like -->
        <div class="toolbar-items">
            @if (isFavorite()) {
            <span (click)="outHandleFavoriteChange.emit()">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                    fill="#38424b">
                    <path
                        d="m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Z" />
                </svg>
            </span>
            } @else {
            <span (click)="outHandleFavoriteChange.emit()">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                    fill="#38424b">
                    <path
                        d="m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Zm0-108q96-86 158-147.5t98-107q36-45.5 50-81t14-70.5q0-60-40-100t-100-40q-47 0-87 26.5T518-680h-76q-15-41-55-67.5T300-774q-60 0-100 40t-40 100q0 35 14 70.5t50 81q36 45.5 98 107T480-228Zm0-273Z" />
                </svg>
            </span>
            }
        </div>
        <!-- Dislike -->
        <div class="toolbar-items">
            @if (isDislike()) {
            <span (click)="outPrevDislikeProduct.emit()">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                    fill="#38424b">
                    <path
                        d="M240-840h400v520L360-40l-50-50q-7-7-11.5-19t-4.5-23v-14l44-174H120q-32 0-56-24t-24-56v-80q0-7 1.5-15t4.5-15l120-282q9-20 30-34t44-14Zm480 520v-520h160v520H720Z" />
                </svg>
            </span>
            } @else {
            <span (click)="outPrevDislikeProduct.emit()">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                    fill="#38424b">
                    <path
                        d="M240-840h440v520L400-40l-50-50q-7-7-11.5-19t-4.5-23v-14l44-174H120q-32 0-56-24t-24-56v-80q0-7 2-15t4-15l120-282q9-20 30-34t44-14Zm360 80H240L120-480v80h360l-54 220 174-174v-406Zm0 406v-406 406Zm80 34v-80h120v-360H680v-80h200v520H680Z" />
                </svg>
            </span>
            }
        </div>
    </div>
</ng-template>