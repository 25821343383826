import { Injectable } from "@angular/core";
import { BundleModifyTypes } from '../types/flows-config.types';
import { PreOrderPayloadProduct, Product } from "../../product/product.types";
import { environment } from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class ProductCardV2Service {


    #bundleModifyType: string = environment.config.flows.bundles.modifyType;

    setUpProductForPreOrder(data: { product: Partial<Product>, variantId: number, packageId?: number, quantity: number }): PreOrderPayloadProduct {
        const { product, variantId, packageId, quantity } = data;
        if (!product.id) throw new Error('');
        return {
            id: product.id,
            quantity,
            variantId,
            packageId
        }
    }

    getButtonText(
        data: {
            conf: { isBundle: boolean, isSubscription: boolean },
            existsInOrder: any,
            product: Product,
            isPreorderedVariant: boolean,
            isSubscribeAndSave: boolean,
            skippedSubscriptionLineId: number | null;
        }
    ) {
        const { conf: { isBundle, isSubscription }, existsInOrder, product, isPreorderedVariant, isSubscribeAndSave, skippedSubscriptionLineId } = data;

        if (isSubscription && skippedSubscriptionLineId)
            return 'Unskip';

        if (isBundle) {
            if (isSubscription) {
                return existsInOrder.subscription ? (this.#bundleModifyType === BundleModifyTypes.outOfOrder ? 'Customize' : 'Subscribed') : isSubscribeAndSave ? 'Subscribe & Save' : 'Subscribe'
            } else {
                return existsInOrder.common ? (this.#bundleModifyType === BundleModifyTypes.outOfOrder ? 'Customize' : 'Added') : 'Add'
            }
        } else {
            if (isSubscription) {
                return existsInOrder.subscription ? 'Subscribed' : isSubscribeAndSave ? 'Subscribe & Save' : 'Subscribe';
            } else {
                if (product.preOrder) {
                    return isPreorderedVariant ? 'Pre Ordered' : 'Pre Order'
                }
                return existsInOrder.common ? 'Added' : 'Add';
            }
        }
    }

    getVariant(stock: Map<number | string, any>, productId: number, selectedAttribute: any) {
        if (!stock?.size) return null;

        const stockMapKey = this.getKeyForStockMap(productId, selectedAttribute);
        const variant = stock.get(stockMapKey);
        if (variant) {
            variant.id = variant.variantId;
        }
        return variant ?? null;
    }

    getVariantFromPackage(stock: Map<number | string, any>, productId: number, selectedAttribute: any) {
        if (!stock.size) return null;

        const stockMapKey = this.getKeyForStockMap(productId, selectedAttribute);
        const variant = stock.get(stockMapKey);
        if (variant) {
            variant.id = variant.variantId;
            variant.attribute = selectedAttribute || null
        }
        return variant ?? null;
    }

    getKeyForStockMap(productId: number, selectedAttribute?: any) {
        let key: any = productId;
        if (selectedAttribute?.id && selectedAttribute?.value?.id)
            key = `${productId}_${selectedAttribute.id}_${selectedAttribute.value.id}`;

        return key;
    }
}