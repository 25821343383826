import { Component, computed, inject, input, InputSignal, Signal } from '@angular/core';
import { SignalsStoreService } from '../../signals-store.service';
import { ProductResume } from '../../../product/product.types';
import { MarketStatus } from '../../types/common.types';
import { ResolutionService } from '../../resolution.service';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-product-card-v2-resume',
  imports: [
    NgbModule,
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule
  ],
  templateUrl: './product-card-v2-resume.component.html',
  styleUrl: './product-card-v2-resume.component.scss'
})
export class ProductCardV2ResumeComponent {

  //#region Injection:
  #signalsStoreService = inject(SignalsStoreService);
  #resolutionService = inject(ResolutionService);
  //#endregion

  //#region Input:
  product: InputSignal<ProductResume> = input.required();
  isFromWelcome: InputSignal<boolean> = input.required();
  //#endregion

  //#region Computed:
  hasSession: Signal<boolean> = computed(() => this.#signalsStoreService.hasSession());
  marketStatus: Signal<MarketStatus> = computed(() => this.#signalsStoreService.marketStatus());
  isMobile: Signal<boolean> = computed(() => this.#resolutionService.isMobile());
  //#region 

}
