export const UnableToCancelSubscription = {
  title: 'Subscription Cancellation Not Allowed',
  textContent: `
    <p class="text-start">Your membership requires you to remain subscribed to at least one FarmBox.</p>
    <p class="text-start">If you’d like to change your FarmBox selection, please complete <a rel="noopener" target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLScBa__EF9rGfl88CdlL_I-NSAPtOrsKvLVLi_9AY-TTy6wmuw/viewform">this form</a>, and we will make the necessary adjustments. A team member will reach out via email to confirm once you changehas been processed.</p>
    <p class="text-start"><strong>Important:</strong> If you submit your request after 5 PM on Sunday and have an upcoming order that week, we will do our best to accommodate the change, but we cannot guarantee we will have all items in stock.</p>
    <p class="fw-bold text-start">Cancelling Your Subscription:</p>
    <p class="text-start">To cancel your subscription, please contact our support team via info@farmboxdl.com. A team member will reach out via email to confirm once your cancellation has been processed.</p>
    <p class="text-start"><strong>Important:</strong> If you submit your cancellation request after 5 PM on Sunday and have an upcoming order that week, you will still be responsible for that order.</p>`
}
