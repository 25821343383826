import { Component, computed, ElementRef, inject, input, InputSignal, output, QueryList, signal, Signal, ViewChild, ViewChildren, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignalsStoreService } from '../../signals-store.service';
import { handleImageError } from '../../common/utils';
import { CardTypes, ProductCard } from '../../product-card/product-card.types';
import { ProductCardV2Service } from '../product-card-v2.service';
import { RouterLink } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { BundleModifyTypes } from '../../types/flows-config.types';
import { ResolutionService } from '../../resolution.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { NumberRestrictionDirective } from '../../directives/number-restriction.directive';
import { OutOfStockComponent } from '../../product-card/out-of-stock/out-of-stock.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-product-card-v2-main',
  imports: [
    CommonModule,
    RouterLink,
    MatFormFieldModule,
    MatSelectModule,
    NumberRestrictionDirective,
    OutOfStockComponent,
    NgbModule,
    MatInputModule
  ],
  templateUrl: './product-card-v2-main.component.html',
  styleUrl: './product-card-v2-main.component.scss'
})
export class ProductCardV2MainComponent {

  //#region ViewChildren
  @ViewChild('tagsContainer') tagsContainer!: ElementRef;
  @ViewChildren('tagsItemList') tagsItemList!: QueryList<ElementRef>;
  //#endregion

  //#region Injections
  #signalsStoreService = inject(SignalsStoreService);
  #productCardV2Service = inject(ProductCardV2Service);
  #resolutionService = inject(ResolutionService);
  //#endregion

  //#region Inputs
  card: InputSignal<ProductCard> = input.required();
  isOutOfStock: InputSignal<boolean> = input.required();
  isOutOfStockPerQuantity: InputSignal<boolean> = input.required();
  hasNotify: InputSignal<boolean> = input.required();
  productAttributes: InputSignal<Array<any>> = input.required();
  selectedAttribute: InputSignal<any> = input.required();
  selectedPackage: InputSignal<any> = input.required();
  selectedAttributeCardConfig: InputSignal<any> = input.required();
  wasValueHigher10: InputSignal<boolean> = input.required();
  newQuantity: InputSignal<number> = input.required();
  totalStockAvailable: InputSignal<number> = input.required();
  availableQuantities: InputSignal<Array<{ val: number, name: string | number }>> = input.required();
  stockSignal: InputSignal<Map<number | string, any>> = input.required();
  isDisabledActionButtons: InputSignal<boolean> = input.required();
  isDisabledSubscribeButton: InputSignal<boolean> = input.required();
  existsInOrder: InputSignal<{ common: boolean, subscription: boolean, totalQuantity: number }> = input.required();
  isPreOrderedVariant: InputSignal<boolean> = input.required();
  productId: InputSignal<number> = input.required();
  isFromWelcome: InputSignal<boolean> = input.required();
  isSignupFlow: InputSignal<boolean> = input.required();
  isDislike: InputSignal<boolean> = input.required();
  skippedSubscriptionLineId: InputSignal<number | null> = input.required();
  subscribeAndcustomizeBox: InputSignal<boolean> = input.required();
  isFavorite: InputSignal<boolean> = input.required();
  //#endregion

  //#region Outputs
  outFlipCardType = output<{ type: CardTypes }>();
  outModalCheckAddress = output();
  outOnAttributeChange = output<{ id: number, event: any }>();
  outOnPackageChanged = output<any>();
  outOnInputQuantityChange = output<any>();
  outOnKeyUp = output<any>();
  outOnQuantityChange = output<any>();
  outAddProductToCart = output<{ isSubscription: boolean, event: any }>();
  outOpenModalCheckAddress = output();
  outOpenModalWhatsInside = output<any>();
  outPrevDislikeProduct = output();
  outHandleFavoriteChange = output();
  //#endregion

  //#region Signals
  updateLikeDislike: WritableSignal<boolean> = signal(false);
  imgPlaceholder = signal('assets/images/product-placeholder.webp');
  showText = signal(false);
  //#endregion

  //#region Computed
  hasSession: Signal<boolean> = computed(() => this.#signalsStoreService.hasSession());
  marketStatus = computed(() => this.#signalsStoreService.marketStatus());
  isMobile = computed(() => this.#resolutionService.isMobile());

  showSaleBadge = computed(() => {
    return this.selectedAttributeCardConfig().hasDiscount && this.selectedAttributeCardConfig().isOnSale
  });

  productUrl = computed(() => {
    return this.card().product?.productUrl;
  });

  productImg = computed(() => {
    const imagePlaceholder = this.imgPlaceholder();
    return this.selectedAttributeCardConfig().image || imagePlaceholder;
  });

  productRouterLink = computed(() => {
    const isFromWelcome = this.isFromWelcome();
    if (isFromWelcome) return null;
    return [`/shop/${this.productUrl()}`];
  });

  showHoveredButton = computed(() => this.card().product.bundle?.items?.length);

  showMarketClosedSection = computed(() => {
    return !this.marketStatus().isOpen && !this.card().product.preOrder &&
      !this.selectedAttributeCardConfig().isSubscription
  });

  showInOrderOverlay = computed(() => {
    return (this.existsInOrder().common || this.existsInOrder().common);
  });

  productNameTooltip = computed(() => {
    return this.card().product.name;
  });

  productName = computed(() => {
    return this.card().product.name;
  });

  producerName = computed(() => {
    return this.card().product.producer?.name;
  });

  showOOSComponent = computed(() => {
    return (this.isOutOfStock() || this.isOutOfStockPerQuantity());
  });

  productDisplay = computed(() => {
    return this.selectedAttributeCardConfig().display;
  });

  productTags = computed(() => {
    return this.card().product.tags;
  });

  getButtonTextBundleSubscription = computed(() =>
    this.#getButtonText({ isBundle: true, isSubscription: true })
  );

  getButtonTextBundle = computed(() =>
    this.#getButtonText({ isBundle: true, isSubscription: false })
  );

  getButtonTextSingle = computed(() =>
    this.#getButtonText({ isBundle: false, isSubscription: false })
  );

  getButtonTextSubscription = computed(() =>
    this.#getButtonText({ isBundle: false, isSubscription: true })
  );
  //#endregion

  //#region Properties:
  cardTypes = CardTypes;
  bundleModifyTypes = BundleModifyTypes;
  bundleModifyType = environment.config.flows.bundles.modifyType;
  //#endregion

  //#region Methods
  handleImageError = handleImageError;

  #getButtonText(conf: { isBundle: boolean, isSubscription: boolean }) {
    const product = this.card().product;
    if (!product) return '';
    return this.#productCardV2Service.getButtonText({
      conf,
      existsInOrder: this.existsInOrder(),
      isPreorderedVariant: this.isPreOrderedVariant(),
      product,
      isSubscribeAndSave: this.selectedAttributeCardConfig().isSubscribeSave,
      skippedSubscriptionLineId: this.skippedSubscriptionLineId()
    })
  }
  //#endregion

}
