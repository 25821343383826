<section class="d-flex flex-column flex-grow-1">

    <div class="product-card__img-wrap">

        <a>
            <div class="product-card__img"></div>
        </a>

    </div>

    <!-- Card Header - Title and Subtitle -->
    <div class="product-card__head">
        <a>
            <h3 class="product-card__title line-clamp-1">{{product().name}}</h3>
        </a>
        <h5 class="product-card__subtitle">{{product().producer?.name}}</h5>
    </div>

    <section>
        <div class="product-card__meta">
            <div class="product-card__meta--item is-col-2">
                <div class="d-grid row-gap-1">

                    <div class="d-flex gap-1 d-flex gap-1 flex-row align-items-center">

                        <h5 class="product-card__price">
                            <span class="material-symbols-outlined rotate-icon">
                                progress_activity
                            </span>
                        </h5>

                    </div>
                    @if (product().display) {
                    <p class="product-card__info">
                        {{product().display || ''}}
                    </p>
                    }
                </div>

                <!-- Quantity buttons -->
                <div class="col quantity">
                    <mat-form-field class="rounded-selector d-flex" subscriptSizing="dynamic">
                        <mat-select value="1">
                            <mat-option value="1">1</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

            </div>

            <!-- @if (selectedAttributeCardConfig().teaser && subscribeAndcustomizeBox()) {
            <p class="product-card__teaser" [innerHTML]="selectedAttributeCardConfig().teaser"></p>
            } -->

            <div class="row">
                <!-- Product Customizations  -->
                <div class="col-12 d-flex gap-1">
                    @if (product().attributes?.length) {
                    @for (attr of product().attributes; track $index) {
                    @if (attr.values.length) {
                    <mat-form-field class="rounded-selector" subscriptSizing="dynamic">
                        <mat-label class="text-capitalize">{{attr.name.toLowerCase()}}</mat-label>
                        @if (attr.values.length === 1) {
                        <input matInput type="text" readonly="true" [value]="attr.values[0].name">
                        } @else {
                        <mat-select [value]="attr.values[0].id || ''">
                            @for (item of attr.values; track $index) {
                            <mat-option [value]="item.id">{{item.name}}</mat-option>
                            }
                        </mat-select>
                        }
                    </mat-form-field>
                    }
                    }
                    }
                    @if (product().packages?.length) {
                    <mat-form-field class="rounded-selector" subscriptSizing="dynamic">
                        <mat-label class="text-capitalize">Package</mat-label>
                        @if (product().packages?.length === 1) {
                        <input matInput type="text" readonly="true" [value]="product().packages?.[0].name || ''">
                        } @else {
                        <mat-select [value]="product().packages?.[0].id || ''">
                            @for (item of product().packages; track $index) {
                            <mat-option [value]="item.id">{{item.name}}</mat-option>
                            }
                        </mat-select>
                        }
                    </mat-form-field>
                    }
                </div>
            </div>


            <!-- Tags Icons -->
            @let tags = product().tags || [];
            @if (tags.length) {
            <div class="my-2">
                <ul class="product-card__tags">
                    @for (tag of tags; track $index) {
                    <li>
                        {{tag.name}}
                    </li>
                    }
                </ul>
            </div>
            }

        </div>

    </section>


</section>